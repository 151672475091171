<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		
		<div class="tableModule" v-if="type==0">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>采集站列表</span>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;cursor: pointer;"
							@row-click="rowClick"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="no" align="center" label="采集站编码" show-overflow-tooltip></el-table-column>
							<el-table-column prop="ip" align="center" label="IP地址" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="存储状态" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.row.store_used+'M / '+scope.row.store_size+'M'}}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="系统状态(当前)" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.row.status==0?'正常':'离线'}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="time" align="center" label="诊断时间" show-overflow-tooltip></el-table-column>
							<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip></el-table-column>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
						</el-table>
					</div>
				</div>
				<!-- <div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div> -->
			</div>
		</div>
		<div class="tableModule" v-show="type==1">
			<div class="title" style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>采集站编码：{{row.no}}</span>
				</div>
				<div>
					  <el-button type="primary" size="mini" @click="back()">返回</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainers">
					<div>
						<el-table
							ref="usertable"
							:data="tableData"
							border
							:height="tableHeights"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="no" align="center" label="通道号" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="传感器类型" show-overflow-tooltip>
								<template slot-scope="scope">
									<div style="height: 40px;line-height: 40px;">{{ scope.row.type==0?'振动':scope.row.type==1?'转速':scope.row.type==2?'温度':'油液'}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="number" align="center" label="传感器型号" show-overflow-tooltip></el-table-column>
							<el-table-column prop="meas_name" align="center" label="测点名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="voltage" align="center" label="偏置电压" show-overflow-tooltip></el-table-column>
							<el-table-column prop="remarks" align="center" label="备注" show-overflow-tooltip></el-table-column>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
						</el-table>
					</div>
				</div>
				<!-- <div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			tableHeight: 0,
			tableHeights:0,
			tableData: [],
			searchParm: {
				size: 20,
				current: 1
			},
			type:0,
			row:{}
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				if(this.type==0){
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				}else{
					const rects = this.$refs.tableContainers.getBoundingClientRect();
					this.tableHeights = rects.height;
				}
				
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			let url;
			if(this.type==0){
				url = '/backend-api/eqp/sta/v2/list'
			}else{
				url = '/backend-api/eqp/sta/sen/v2/list?sta_id='+this.row.id;
			}
			this.tableData = []
			this.$get(url, this.searchParm).then(res => {
				if (res.code == 1000) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		

		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		rowClick(row){
			this.row = row;
			this.type = 1;
			this.searchParm={
				size: 20,
				current: 1
			}
			this.calHeight();
			this.list();
			
		},
		back(){
			this.type = 0;
			this.searchParm={
				size: 20,
				current: 1
			}
			this.list();
		}
	},
	mounted() {
		this.list();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
		this.showlookListDialog = false;
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
